import BaseService from "./base.service";
import http from "../http-common";
import { customizeError, getResponseData } from "@helpers/_functions";
class EntrepriseService extends BaseService {
  constructor() {
    super("enterprises");
  }
  listByUser(id) {
    return http.get(`${this.path}/listByUser/${id}`);
  }
  updateByUser(item) {
    return http.put(`${this.path}/updateByUser/${item._id}`, item);
  }

  getById(id) {
    return http.get(`${this.path}/get/${id}`);
  }

  getEnterprisesByUserEmail(userEmail) {
    return http.get(`${this.path}/list-by-user-mail/${userEmail}`);
  }
  createCustomDomain(customDomain) {
    return http
      .post(`${this.path}/custom-domain/create`, customDomain)
      .then((res) => getResponseData(res))
      .catch((err) => Promise.reject(customizeError(err)));
  }
  createLoginCustomDomain(customDomain) {
    return http
      .post(`${this.path}/login-custom-domain/create`, customDomain)
      .then((res) => getResponseData(res))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  deleteCustomDomain({ enterpriseId, domainName }) {
    return http
      .delete(`${this.path}/custom-domain/remove/${enterpriseId}/${domainName}`)
      .then((res) => getResponseData(res))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  deleteLoginCustomDomain({ enterpriseId, domainName }) {
    return http
      .delete(
        `${this.path}/login-custom-domain/remove/${enterpriseId}/${domainName}`
      )
      .then((res) => getResponseData(res))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  verifyCustomDomain(domainName) {
    return http
      .get(`${this.path}/custom-domain/verify/${domainName}`)
      .then((res) => getResponseData(res))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  verifyLoginCustomDomain(domainName) {
    return http
      .get(`${this.path}/login-custom-domain/verify/${domainName}`)
      .then((res) => getResponseData(res))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  getCustomDomainByEnterpriseId(enterpriseId) {
    return http
      .get(`${this.path}/custom-domain/getByEnterpriseId/${enterpriseId}`)
      .then((res) => getResponseData(res))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  getLoginCustomDomainByEnterpriseId(enterpriseId) {
    return http
      .get(`${this.path}/login-custom-domain/getByEnterpriseId/${enterpriseId}`)
      .then((res) => getResponseData(res))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  getEmailSenderByEnterprise(enterpriseId) {
    return http
      .get(`${this.path}/email-sender/getByEnterprise/${enterpriseId}`)
      .then((res) => getResponseData(res))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  createEmailSender(emailSender) {
    return http
      .post(`${this.path}/email-sender/create`, emailSender)
      .then((res) => getResponseData(res))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  updateEmailSender(emailSender) {
    return http
      .put(`${this.path}/email-sender/update`, emailSender)
      .then((res) => getResponseData(res))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  updateURL(enterpriseId, url) {
    return http
      .put(`${this.path}/update-url/${enterpriseId}`, { custom_url: url })
      .catch((err) => Promise.reject(customizeError(err)));
  }

  updateUseTimeBank(enterpriseId, useTimeBank) {
    return http
      .put(`${this.path}/updateUseTimeBank/${enterpriseId}`, { useTimeBank })
      .catch((err) => Promise.reject(customizeError(err)));
  }

  deleteEmailSender({ enterpriseId, email }) {
    return http
      .delete(`${this.path}/email-sender/delete/${enterpriseId}/${email}`)
      .then((res) => getResponseData(res))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  resendVerificationEmail(emailSender) {
    return http.post(`${this.path}/email-sender/resend-email`, emailSender);
  }

  getLogosByCustomUrl(customUrl) {
    return http.get(`${this.path}/get-logos/${customUrl}`);
  }

  getLogos(params) {
    return http
      .get(`${this.path}/get-logos`, { params })
      .then((res) => getResponseData(res));
  }
}

export default new EntrepriseService();
