import React from "react";
import { Disclosure } from "@headlessui/react";
import bigArrow from "@assets/image/icones/BigArrow.svg";

const LitleDisclosure = ({
  elementTitle,
  childrenTitle,
  title,
  titleIcon,
  children,
  defaultOpen,
  spaceTitle,
  classSpaceTitle,
  classParent,
  innerRef,
  customArrow,
  customArrowShape,
  LitleTitle
}) => {
  return (
    <div className={classParent + " arh-w-full "} ref={innerRef}>
      <Disclosure defaultOpen={defaultOpen}>
        {({ open }) => (
          <>
            <Disclosure.Button className="arh-flex arh-justify-between arh-items-center arh-w-full arh-bg-white arh-border-0 arh-px-4 arh-pb-[0.695rem] arh-pt-[0.775rem] arh-text-left">
              {LitleTitle ? (
                <label className="arh-flex arh-items-center arh-font-poppins arh-font-medium arh-text-xs arh-mb-1 arh-text-black  ">
                  {titleIcon}
                  <span className={spaceTitle ? classSpaceTitle : "arh-ml-12"}>
                    {title}
                  </span>
                  {elementTitle ? childrenTitle : ""}
                </label>
              ) : (
                <h6 className="arh-flex arh-items-center  arh-text-darkColor arh-font-poppins arh-font-bold arh-text-[0.938rem] arh-m-0">
                  {titleIcon}
                  <span className={spaceTitle ? classSpaceTitle : "arh-ml-12"}>
                    {title}
                  </span>
                  {elementTitle ? childrenTitle : ""}
                </h6>
              )}
              <img
                src={customArrow ? customArrowShape : bigArrow}
                alt="Arrow icon"
                className={`${
                  open
                    ? "arh-transition arh-ease-in arh-duration-[100ms] arh-transform arh-rotate-90"
                    : "arh-transition arh-ease-in arh-duration-[100ms] arh-transform arh-rotate-0"
                }`}
              />
            </Disclosure.Button>

            <Disclosure.Panel unmount={false}>{children}</Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default LitleDisclosure;
