import BaseService from "./base.service";
import http from "../http-common";

class HolidayNameService extends BaseService {
  constructor() {
    super("holiday-name");
  }

  upsert(data) {
    return http.post(`${this.path}/upsert`, data);
  }
}

export default new HolidayNameService();