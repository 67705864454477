import BaseService from "./base.service";
import http from "../http-common";
import { checkEnterpriseId } from "@helpers/_functions";

class TimeBankService extends BaseService {
  constructor() {
    super("timebank");
  }

  getByEmployee(employeId) {
    return http.get(`${this.path}/nbHours/${employeId}`);
  }

  upsert(data) {
    const enterpriseId = checkEnterpriseId();
    return http.post(`${this.path}/upsert`, data, { params: { enterpriseId } });
  }
}

export default new TimeBankService();
 