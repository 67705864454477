import { getResponseData } from "../helpers/_functions";
import http from "../http-common";
import BaseService from "./base.service";

class WorkingHourService extends BaseService {
  constructor() {
    super("timeclocks");
  }
  deleteByEnterprise(id) {
    return http.delete(`${this.path}/deleteByEnterprise/${id}`);
  }
  getByEmployer(employerId) {
    return http.get(`${this.path}/getByEmployer/${employerId}`);
  }

  updateTimeClockEmployees(data) {
    return http
      .patch(`${this.path}/update-employees`, data)
      .then((response) => getResponseData(response));
  }
}

export default new WorkingHourService();
