import BaseService from "./base.service";
import http from "../http-common";
class EmployerEmplacementService extends BaseService {
  constructor() {
    super("employer-emplacement");
  }

  insert(data) {
    return http.post(`${this.path}/create`, data);
  }

  getByEmployer(id) {
    return http.get(`${this.path}/listByEmployer/${id}`);
  }
}

export default new EmployerEmplacementService();
