import {
  checkEnterpriseId,
  customizeError,
  getResponseQuery
} from "@helpers/_functions";

import http from "../http-common";
import BaseService from "./base.service";

class EmployerService extends BaseService {
  constructor() {
    super("employes");
  }
  getEmployerById(id) {
    return http.get(`${this.path}/by/${id}`);
  }
  getUserByEmployer(email) {
    return http.get(`${this.path}/getUserByEmployer/${email}`);
  }
  getByUserId(id, params = {}) {
    params = {
      ...params,
      userId: params.userId || localStorage.getItem("userId")
    };
    return http
      .get(`${this.path}/list/${id}`, { params })
      .then((response) => response.data.data?.[0]);
  }
  getEmailsAndEmployeeNumber(id){
    return http
      .get(`${this.path}/emails-and-employee-number/${id}`)
      .then((response) => response.data.data?.[0]);
  }

  getEmployersForDropdown(enterpriseId) {
    enterpriseId = checkEnterpriseId(enterpriseId);
    return this.getByEnterprise(enterpriseId)
      .then((allEmployersData) => {
        return allEmployersData?.[0]?.docs;
      })
      .catch((err) => {
        return Promise.reject(customizeError(err));
      });
  }

  getEmploiSalaireByUserId(id, params = {}) {
    return http.get(`${this.path}/list/${id}`, params);
  }
  updateUserById(item, id) {
    return http.put(`${this.path}/updateUser/${id}`, item);
  }
  sendPassword(item, id) {
    return http.put(`${this.path}/send-password/${id}`, item);
  }
  changeStatusOrLang(item, id) {
    return http.put(`${this.path}/change/${id}`, item);
  }
  getEmployerFiltered(id, params = {}) {
    params = {
      ...params,
      userId: localStorage.getItem("userId")
    };
    return http.get(`${this.path}/filter/${id}`, { params });
  }
  getListEmployerFiltered(id, filters = "{}", params = {}) {
    params = {
      ...params,
      userId: localStorage.getItem("userId")
    };
    return http.get(`${this.path}/listFiltered/${id}/${filters}`, { params });
  }
  getListEmployerToExport(filters = {}, params = {}) {
    const adminId = localStorage.getItem("idAdminEnterprise");

    params = {
      ...params,
      userId: localStorage.getItem("userId")
    };
    return getResponseQuery(
      http.get(`${this.path}/export-data/${adminId}/${filters}`, { params })
    );
  }

  getWorkingHoursSummaryToExport(id, filters = "", params = {}) {
    params = {
      ...params,
      userId: localStorage.getItem("userId"),
      ...JSON.parse(filters)
    };
    return http.get(`${this.path}/export-recap/${id}`, { params });
  }

  getEmployeStatistics(adminId, filter, params = {}) {
    filter = JSON.stringify(filter);
    params = {
      ...params,
      enterpriseId: checkEnterpriseId(),
      userId: localStorage.getItem("userId")
    };
    return http.get(`${this.path}/statistics/${adminId}/${filter}`, { params });
  }
  getSalaryStatistics(adminId, params = {}, filter) {
    filter = JSON.stringify(filter);
    params = {
      ...params,
      userId: localStorage.getItem("userId")
    };
    return http.get(`${this.path}/salary-statistics/${adminId}/${filter}`, {
      params
    });
  } 

  getSalaryEvolution(adminId, params = {}, filter){
    filter = JSON.stringify(filter);
    params = {
      ...params,
      userId: localStorage.getItem("userId")
    };
    return http.get(`${this.path}/salary-evolution/${adminId}/${filter}`, {
      params
    });
  }
  getSalaryEvolutionsByYears(adminId, params = {}, filter){
    filter = JSON.stringify(filter);
    params = {
      ...params,
      userId: localStorage.getItem("userId")
    };
    return http.get(`${this.path}/salary-evolution-by-years/${adminId}/${filter}`, {
      params
    });
  }
  getEmployerAdminByStatus(userId) {
    return http.get(`${this.path}/getEmployerAdminByStatus/${userId}`);
  }
  getByEmplacement = (emplacemetId) => {
    return http.get(`${this.path}/getByEmplacement/${emplacemetId}`);
  };

  importLists(data) {
    return http
      .post(`${this.path}/import`, data)
      .then((response) => response.data.data)
      .catch((err) => Promise.reject(customizeError(err)));
  }
  downloadGabarit() {
    return http.get(`${this.path}/gabarit`, {
      responseType: "blob"
    });
  }
  saveLists(data) {
    return http
      .post(`${this.path}/save`, data)
      .then((response) => response.data.data)
      .catch((err) => Promise.reject(customizeError(err)));
  }
  createEmployer = (dataEmployer) => {
    return http.post(`${this.path}/create-new-employee`, dataEmployer);
  };
  createEmployers = (employers,userId,enterpriseId) => {
    return http.post(`${this.path}/add-multiple/${userId}/${enterpriseId}`, employers);
  };
  updateEmployers = (employers,userId,enterpriseId) => {
    return http.post(`${this.path}/update-multiple/${userId}/${enterpriseId}`, employers);
  };
  export(data) {
    return http.post(`${this.path}/export`, data);
  }

  download(theFileName) {
    return http.get(`${this.path}/download/${theFileName}`, {
      responseType: "blob"
    });
  }

  getByEmployerAccess(userId, params = {}) {
    return http
      .get(`${this.path}/employer-access/${userId}`, { params })
      .then((response) => response.data.data);
  }

  getEmployerHierarchical(id) {
    return http.get(`${this.path}/get-employer-hierarchical/${id}`);
  }

  upsertHealthPrecision(body) {
    return http.post(`${this.path}/health-precision-option/upsert`, body);
  }

  deleteHealthById(id) {
    return http.delete(`${this.path}/health-precision-option/deleteById/${id}`);
  }

  getHealthByEnterprise(id) {
    return http.get(
      `${this.path}/health-precision-option/getByEnterprise/${id}`
    );
  }
  upsertClothes(body) {
    return http.post(`${this.path}/clothes/upsert`, body);
  }

  deleteClothesById(id) {
    return http.delete(`${this.path}/clothes/deleteById/${id}`);
  }

  getClothesByEnterprise(id) {
    return http.get(
      `${this.path}/clothes/getByEnterprise/${id}`
    );
  }
}

export default new EmployerService();
