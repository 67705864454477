import { makeActionHandler } from "./_reducerUtils";

export const reportData = {
  initialState: {
    reportsHours: {
      list: [],
      filter: {},
      isLoaded: false,
      isFiltered: false
    },
    resumeHours: {
      list: [],
      filter: {},
      greatTotal: {},
      isLoaded: false,
      employeProjects: {},
      formationsHours: [],
      showAssignation: false
    }
  },
  handlers: {
    "WORKED_HOURS/SET_REPORTS_HOURS": makeActionHandler("reportsHours"),
    "WORKED_HOURS/SET_RESUME_HOURS": makeActionHandler("resumeHours"),
    "RESET_ALL": () => reportData.initialState
  }
};

export default reportData;
