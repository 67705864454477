
import { makeActionHandler } from "./_reducerUtils";
import { INIT_EVOLUTION } from "@helpers/_constants";

const enterpriseEmployeData = {
  initialState: {
    employeList: {
      list: [],
      isLoaded: false,
    },
    courseAndSalary: {
      list: [],
      isLoaded: false,
      employeId: null,
    },
    certification: {
      list: [],
      isLoaded: false,
      employeId: null,
    },
    formation: {
      list: [],
      isLoaded: false,
      employeId: null,
    },
    note: {
      list: [],
      isLoaded: false,
      employeId: null,
    },
    salaryStatistics: {
      list: [],
      details: {},
      filter: {},
      devise: "",
      isLoaded: false,
      pagination: 0,
      isFiltered: false,
    },
    salaryEvolutions: {
      dataMonth: {},
      dataYears:{},
      // details: {},
      filter: {},
      devise: "",
      year: new Date().getFullYear() ,
      years: [...INIT_EVOLUTION],
      isLoaded: false,
      // pagination: 0,
      isFiltered: false,
    },
    employeStatistics: {
      data: null,
      filter: {},
      isLoaded: false,
      isFiltered: false,
      pagination: {
        antiquity: 0,
        age: 0,
        conge: 0,
        rotation: new Date().getFullYear(),
      },
    },
    birthAnniversary: {
      list: [],
      count: 0,
      initial: 0,
      isLoaded: false,
      isFiltered: false,
      filter: {},
    },
    hiringAnniversary: {
      list: [],
      count: 0,
      initial: 0,
      isLoaded: false,
      isFiltered: false,
      filter: {},
    },
    enterpriseConge: {
      list: [],
      filter: {},
      isLoading: false,
      isLoaded: false,
      isFiltered: false,
    },
    files: {
      list: [],
      employeId: null,
      isLoaded: false,
      isReady: false,
    },
    folders: {
      list: [],
      employeId: null,
      isLoaded: false,
      isReady: false,
    },
    allergies: {
      list: [],
      isLoaded: false,
    },
    foods: {
      list: [],
      isLoaded: false,
    },
    clothes:{
      list: [],
      isLoaded: false,
    },
    enterpriseTask: {
      list: [],
      isLoaded: false,
      filter: {},
      isFiltered: false,
      isRefreshing: false,
      evaluationRequestList: [],
      onboardingTaskList: [],
    },
  },
  handlers: {
    "enterpriseEmployeData/SET_EMPLOYE_LIST": makeActionHandler("employeList"),
    "enterpriseEmployeData/SET_COURSE_AND_SALARY":
      makeActionHandler("courseAndSalary"),
    "enterpriseEmployeData/SET_CERTIFICATION":
      makeActionHandler("certification"),
    "enterpriseEmployeData/SET_NOTE": makeActionHandler("note"),
    "enterpriseEmployeData/SET_FORMATION": makeActionHandler("formation"),
    "enterpriseEmployeData/SET_SALARY_STATISTICS":
      makeActionHandler("salaryStatistics"),
    "enterpriseEmployeData/SET_EMPLOYE_STATISTICS":
    makeActionHandler("employeStatistics"),
      "enterpriseEmployeData/SET_SALARY_EVOLUTION":
      makeActionHandler("salaryEvolutions"),
    "enterpriseEmployeData/SET_BIRTH_ANNIVERSARY":
      makeActionHandler("birthAnniversary"),
    "enterpriseEmployeData/SET_HIRING_ANNIVERSARY":
      makeActionHandler("hiringAnniversary"),
    "enterpriseEmployeData/SET_ENTERPRISE_CONGE":
      makeActionHandler("enterpriseConge"),
    "enterpriseEmployeData/SET_FILES": makeActionHandler("files"),
    "enterpriseEmployeData/SET_PROFILE_FOLDERS": makeActionHandler("folders"),
    "enterpriseEmployeData/SET_ALLERGIES": makeActionHandler("allergies"),
    "enterpriseEmployeData/SET_FOODS": makeActionHandler("foods"),
    "enterpriseEmployeData/SET_CLOTHES": makeActionHandler("clothes"),
    "enterpriseEmployeData/SET_ENTERPRISE_TASK":
      makeActionHandler("enterpriseTask"),
    RESET_ALL: () => enterpriseEmployeData.initialState,
  },
};

export default enterpriseEmployeData;
