import BaseService from "./base.service";
import http from "../http-common";

class HoraireService extends BaseService {
  constructor() {
    super("horaire");
  }
  getByEmployer(id) {
    return http.get(`${this.path}/getByEmployer/${id}`);
  }

  getOneMonthForCalendarByEmployer(params = {}) {
    return http.get(`${this.path}/getOneMonthForCalendarByEmployer`, {
      params
    });
  }

  getOneMonthHoraryForCalendarByEnteprise(value, params = {}) {
    const { enterpriseId, date, timezone } = value;
    params = {
      ...params,
      timezone: timezone,
      userId: localStorage.getItem("userId")
    };
    return http.get(
      `${this.path}/getOneMonthHoraryForCalendarByEnteprise/${enterpriseId}/${date}`,
      { params }
    );
  }

  deleteMultiple = (params) => {
    return http.post(`${this.path}/deleteMultiple`, { params });
  };

  updateMultiple = (data) => {
    return http.post(`${this.path}/updateMultiple`, data);
  };
}

export default new HoraireService();
