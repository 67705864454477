import BaseService from "./base.service";
import http from "../http-common";
class EmployerGroupService extends BaseService {
  constructor() {
    super("employer-groupe");
  }
  listByEmployer(id) {
    return http.get(`${this.path}/listByEmployer/${id}`);
  }
  getByEmployer(id) {
    return http.get(`${this.path}/groupByEmployer/${id}`);
  }
  getBySubGroup(id) {
    return http.get(`${this.path}/list/${id}`);
  }
  getByGroup(id) {
    return http.get(`${this.path}/listByGroupe/${id}`);
  }

  // @deprecated
  getEmployerGroupsText(employerId) {
    return this.listByEmployer(employerId).then((response) => {
      let groups = [];
      const empoyerGroupes = response.data?.data;
      if (empoyerGroupes?.length) {
        for (const employeGroup of empoyerGroupes) {
          const groupTitle = employeGroup.Groupe?.[0]?.title;
          const subGroupTitle = employeGroup.SousGroupe?.[0]?.title;
          if (groupTitle && !groups.find((title) => title === groupTitle)) {
            groups.push(groupTitle);
          }
          if (
            subGroupTitle &&
            !groups.find((title) => title === subGroupTitle)
          ) {
            groups.push(subGroupTitle);
          }
        }
      }
      return groups.join(", ");
    });
  }

  deleteByGroup(id) {
    return http.delete(`${this.path}/deleteByGroup/${id}`);
  }

  deleteBySubGroup(id) {
    return http.delete(`${this.path}/deleteBySubGroup/${id}`);
  }
}

export default new EmployerGroupService();
