import BaseService from "./base.service";
import http from "../http-common";
import { checkEnterpriseId, getResponseData } from "../helpers/_functions";

class EmployerHoursService extends BaseService {
  constructor() {
    super("employer-hours");
  }
  getByEmployer(id) {
    return http.get(`${this.path}/getByEmployer/${id}`);
  }

  getByEmployerAndDate(id, params = {}) {
    return http.get(`${this.path}/listByDate/${id}`, { params });
  }

  getEmployerHoursWithStatusByEnterprise(enterpriseId, params = {}) {
    enterpriseId = checkEnterpriseId(enterpriseId);
    params = {
      ...params,
      userId: localStorage.getItem("userId")
    };
    return http
      .get(`${this.path}/listEmployersHoursWithStatus/${enterpriseId}`, {
        params
      })
      .then((response) => getResponseData(response));
  }
}

export default new EmployerHoursService();
